<template>
  <div>
    <div class="banner-box">
      <el-carousel height="360px">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <div class="swiper-img">
            <img v-lazy="item.image"/>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="w-1200 info-box">
        <div class="info-content">

        </div>
      </div>

    </div>
    <div class="step-info"></div>
    <div class="f3-topics w-1200 d-f justify-between mt-4">
      <div class="topic-item" v-for="item in f3Topics" :key="item.id">
        <div class="topic-title">
          <i :class="item.titleIcon"></i>
          {{item.title}}
        </div>
        <div class="topic-content d-f justify-start">
          <img v-for="imgItem in item.child" v-lazy="imgItem.img" :key="imgItem.id">
        </div>
      </div>
    </div>
    <div class="f4-brand w-1200 mt-4">
      <div class="title-box">
        <i class="el-icon-trophy"></i>
        行业精选
      </div>
      <div class="brand-list">
        <img v-for="item in f4BrandList" v-lazy="item.img" :key="item.id">
      </div>
    </div>
    <div class="f5-preferential mt-4 mb-2 w-1200">
      <div class="title-box">
        <i class="el-icon-trophy"></i>
        特惠推荐
      </div>
      <div class="preferential-list d-f justify-start">
        <img v-for="item in f5PreferentialList" v-lazy="item.img" :key="item.id"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  userInfo as getUserInfoUrl, getConfigUrl,
} from '@/api/index'

export default {
  name: 'DistributionIndex',
  data () {
    return {
      bannerList: [
        {
          id: 1,
          image: 'https://uploadjava.bizpower.com/image/74/44/74449c0f1a356ce014cf90068edbf171.png'
        }, {
          id: 2,
          image: 'https://uploadjava.bizpower.com/image/b2/bf/b2bfefb54b7f2650670fd7ae71c672f4.jpg'
        }
      ],
      onproto: require('@/assets/img/header-img.jpeg'),
      loginStatus: false,
      userInfo: {},
      shopAppInfo: {},
      f3Topics: [
        {
          id: 1,
          title: '行业好货',
          titleIcon: 'el-icon-goods',
          borderColor: '#63A59D',
          child: [
            {
              id: 11,
              img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_a_1.jpg'
            },
            {
              id: 12,
              img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_a_2.jpg'
            }
          ]
        },
        {
          id: 2,
          title: '高佣分享',
          titleIcon: 'el-icon-money',
          borderColor: '#CFB462',
          child: [
            {
              id: 21,
              img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_a_3.jpg'
            },
            {
              id: 22,
              img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_a_4.jpg'
            }
          ]
        }
      ],
      f4BrandList: [
        {
          id: 1,
          img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_b_1.jpg',
        }, {
          id: 2,
          img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_b_2.jpg'
        }, {
          id: 3,
          img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_b_3.jpg'
        }, {
          id: 4,
          img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_b_4.jpg'
        }, {
          id: 5,
          img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_b_5.jpg'
        }, {
          id: 6,
          img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_b_6.jpg'
        }, {
          id: 7,
          img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_b_7.jpg'
        }, {
          id: 8,
          img: 'https://java.bizpower.com/web/static/img/distribution/temp/index_b_8.jpg'
        }
      ],
      f5PreferentialList:[
        {
          id:1,
          img:'https://java.bizpower.com/web/static/img/distribution/temp/index_c_1.jpg'
        }, {
          id:2,
          img:'https://java.bizpower.com/web/static/img/distribution/temp/index_c_2.jpg'
        }, {
          id:3,
          img:'https://java.bizpower.com/web/static/img/distribution/temp/index_c_3.jpg'
        },
      ]
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getUserInfo()
      this.getShopAppInfo()
    },
    // 获取用户信息
    async getUserInfo () {
      // 先从vuex中判断是否登录
      this.loginStatus = this.$store.state.loginStatus
      console.log(this.loginStatus)
      let token = window.localStorage.getItem('token')
      let userInfo = window.sessionStorage.getItem('userInfo')
      console.log(userInfo)
      console.log(token)
      if (!this.loginStatus) {
        // 再判断缓存中有没有token和用户信息
        if (token == '' || token == null) {
          // token为空清除userInfo的缓存
          window.sessionStorage.removeItem('userInfo')
          this.$store.commit('changeLoginStatusMutations', false)
          return false
        } else {
          this.loginStatus = true
          this.$store.commit('changeLoginStatusMutations', true)
        }
        if (userInfo == '' || userInfo == null) {
          const { data: userInfoRes } = await this.$http.get(getUserInfoUrl)
          if (userInfoRes.code !== 200) return this.$message.error(userInfoRes.msg)
          window.sessionStorage.setItem('userInfo', JSON.stringify(userInfoRes.data))
          console.log(userInfoRes)
          this.userInfo = userInfoRes.data
        } else {
          this.userInfo = JSON.parse(userInfo)
          this.$store.commit('changeLoginStatusMutations', true)
        }
      } else {
        if (userInfo == '' || userInfo == null) {
          const { data: userInfoRes } = await this.$http.get(getUserInfoUrl)
          if (userInfoRes.code !== 200) return this.$message.error(userInfoRes.msg)
          window.sessionStorage.setItem('userInfo', JSON.stringify(userInfoRes.data))
          console.log(userInfoRes)
          this.userInfo = userInfoRes.data
        } else {
          this.userInfo = JSON.parse(userInfo)
          this.$store.commit('changeLoginStatusMutations', true)
        }
      }
    },
    // 获取商城基本信息
    async getShopAppInfo () {
      this.shopAppInfo = this.$store.state.shopAppInfo
      if (JSON.stringify(this.shopAppInfo) == '{}') {
        // 因为会在后台自动配置，随时会修改，不能做缓存所以直接放到vuex中
        const { data: res } = await this.$http.get(getConfigUrl)
        if (res.code !== 200) return this.$message.error(res.msg)
        this.shopAppInfo = res.data
        this.$store.commit('changeShopAppInfoMutations', this.shopAppInfo)
        console.log(res)
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .banner-box {
    position: relative;

    .swiper-img {
      img {
        height: 360px;
      }
    }

    .info-box {
      position: relative;
      top: 0;
      z-index: 99;

      .info-content {
        position: absolute;
        top: -360px;
        right: 0;
        width: 218px;
        height: 360px;
        background-color: rgba(255, 255, 255, .6);
      }

    }
  }

  .step-info {
    background-color: #F7F7FA;
    background-image: url("https://java.bizpower.com/web/static/img/distribution/step_faq.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 90px;
  }

  .f3-topics {
    .topic-item {
      .topic-title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        color: #333;
      }

      .topic-content {
        img {
          width: 295px;
          height: 403px;
          border: 1px solid #dddddd;
          cursor: pointer;
          transition: box-shadow .2s ease-in;

          &:first-child {
            border-right: none;
          }

          &:hover {
            box-shadow: 0 2px 6px 0 rgba(32, 32, 64, .16);
            z-index: 10;
          }
        }

      }
    }
  }
  .title-box{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #333;
  }

  .f4-brand {
    .brand-list{
      display: flex;
      justify-content: start;
      flex-flow: row wrap;
      border-right: 1px solid #E2E2E9;
      border-top: 2px solid #f28430;
      img{
        border-left: 1px solid #E2E2E9;
        border-bottom: 1px solid #E2E2E9;
        z-index: 1;
        width: 298px;
        height: 120px;
        transition: box-shadow .2s ease-in;
        display: block;
        &:hover{
          box-shadow: 0 2px 6px 0 rgba(32, 32, 64, .16);
        }
      }
    }

  }
  .f5-preferential{
    .preferential-list{
      border: 1px solid #E2E2E9;
      border-top: 2px solid #f28430;
      img{
        width: 399px;
        height: 160px;
        border-left: 1px solid #E2E2E9;
        margin-left: -1px;
        position: relative;
        z-index: 1;
        transition: box-shadow .2s ease-in;
        display: block;
        &:hover{
          box-shadow: 0 2px 6px 0 rgba(32, 32, 64, .16);
        }
      }
    }
  }
</style>
